@import "./variables.scss";

*, body {
  font-family: "Montserrat" !important;
  -webkit-font-smoothing: antialiased;
}

.ant-picker-panel {
  border: 0 !important;
}

.ant-layout {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #e3e3e3;
  }

  ::-webkit-scrollbar:vertical {
    width: 10px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid #e3e3e3;
    background-color: #999;

    &:hover {
      background-color: #666;
    }
  }

  ::-webkit-resizer {
    display: none;
  }
}

.ant-picker-cell {
  padding: 6px 0 !important;
}

.ant-picker-cell-disabled {
  .subscriptionDetailsTrigger {
    display: none;
  }

  &::before {
    content: none;
  }
}

.ant-picker-range-separator {
  margin-bottom: auto !important;
}

svg {
  vertical-align: middle;
}

.ant-modal-header,
.ant-modal-content {
  border-radius: $whitespace-1 !important;
}

// .ant-btn:not(.ant-btn-round),
// .ant-input,
// .ant-input-affix-wrapper,
// .ant-modal-content,
// .ant-tag,
// .ant-select-selector,
// .ant-notification-notice,
// .ant-input-password,
// .ant-picker,
// .ant-input-number-affix-wrapper,
// .ant-input-number-input {
//   border-radius: $whitespace-1/2 !important;
// }

.ant-radio-wrapper {
  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border-color: $strawberry !important;
      }
    }

    .ant-radio-input {
      &:focus {
        + .ant-radio-inner {
          box-shadow: 0 0 0 3px rgba($strawberry, 0.2);
        }
      }

      &:focus,
      :hover {
        + .ant-radio-inner {
          border-color: $strawberry !important;

          &:after {
            background-color: $strawberry;
          }
        }
      }
    }

    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: $strawberry !important;

        &:after {
          background-color: $strawberry;
        }
      }
    }
  }
}

.ant-checkbox {
  &.ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &::after {
        background: $strawberry !important;
      }
    }
  }

  .ant-checkbox-input {
    &:focus,
    :hover {
      + .ant-checkbox-inner {
        border-color: $strawberry !important;
      }
    }
  }

  &.ant-checkbox-checked {
    & .ant-checkbox-inner {
      background-color: $strawberry !important;
      border-color: $strawberry !important;
    }

    &:after {
      border-color: $strawberry !important;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $strawberry !important;
    }
  }
}

.ant-table-thead {
  tr {
    th {
      background: hsla(hue($primary), 30%, 98%) !important;
      color: $gray !important;
      user-select: none;
    }
  }
}

.ant-table-body {
  overflow-y: auto !important;
  tr {
    td {
      &.ant-table-column-sort {
        background: $white;
      }
    }

    &.ant-table-row-selected {
      td {
        background: hsla(hue($primary), 100%, 98%, 1) !important;

        &:first-child {
          &:before {
            height: 100%;
            position: absolute;
            left: 0;
            width: 3px;
            top: 0;
            content: "";
            z-index: 999;
            background-color: $strawberry;
          }
        }
      }
    }
  }
}

.ant-upload.ant-upload-drag {
  border-radius: $whitespace-2 !important;
}

.tabs-plain {
  .ant-tabs-nav {
    background-color: transparent !important;
    padding-inline: $whitespace-3;

    &::before {
      border-bottom: 1px solid $smokewhite !important;
    }

    .ant-tabs-ink-bar {
      text-align: center;
      height: 3px !important;
      border-radius: 4px;
      background: $primary;
    }

    .ant-tabs-tab {
      background-color: transparent !important;
      padding: 12px 0px;
      border-radius: 0px;

      + [class~="ant-tabs-tab"] {
        border-radius: 0px;
      }
    }

    [class~="ant-tabs-tab-active"] {
      background-color: transparent !important;

      [class~="ant-tabs-tab-btn"] {
        color: $primary !important;
      }
    }
  }
}

[class~="ant-tabs-nav"] {
  background-color: hsl(hue($primary), 100%, 98%, 0.88);
  border-radius: 25px;

  &::before {
    border-bottom: 0 !important;
  }

  [class~="ant-tabs-tab"] {
    min-width: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    padding: 10px 20px;

    + [class~="ant-tabs-tab"] {
      margin-left: $whitespace-6;
    }
  }

  [class~="ant-tabs-ink-bar"] {
    background-color: transparent;
    // background: linear-gradient(123.97deg, #98258D 20.13%, #413AAD 51.16%, #FA416D 100%);
  }

  [class~="ant-tabs-tab-active"] {
    background-color: $primary !important;

    [class~="ant-tabs-tab-btn"] {
      color: $white !important;
    }
  }
}

.ant-btn {
  display: inline-flex !important;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.ant-btn-ghost {
  &.smoked {
    background-color: $smokewhite !important;
  }
}

.ant-select-item-option-selected {
  background-color: rgba($primary, 0.15) !important;
}

.ant-steps-horizontal {
  .ant-steps-item-content {
    width: 136px !important;
  }

  .ant-steps-item-title {
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: bold;
  }

  .ant-steps-icon {
    height: 48px !important;
    width: 48px !important;
    background: $smokewhite;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    .iconify {
      color: $gray;
    }
  }

  .ant-steps-item-tail {
    top: 18px !important;
    margin-left: 70px !important;
    width: 98% !important;

    &::after {
      height: 2px !important;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-tail {
      &::after {
        background-color: $strawberry !important;
      }
    }
  }

  .ant-steps-item-finish,
  .ant-steps-item-process {
    .ant-steps-item-title {
      color: $primary !important;
    }

    .ant-steps-icon {
      background: $strawberry;

      .iconify {
        color: $white;
      }
    }
  }
}

.ant-form-item {
  margin-bottom: $whitespace-2 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba($primary, 0.15) !important;
}

.PhoneInput {
  [class~="ant-select-single"] {
    width: 20% !important;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.ant-upload-list-item {
  font-size: 1rem !important;

  &:hover {
    .ant-upload-list-item-info {
      background-color: transparent !important;
    }
  }

  .ant-upload-list-item-card-actions-btn {
    opacity: 1 !important;

    .anticon {
      color: $secodnaryRed !important;
    }
  }
}

.ant-modal-header {
  border-bottom: none !important;
}

.ant-modal-footer {
  border-top: none !important;
}

.ant-tooltip-inner {
  color: $primary !important;
  background-color: $white !important;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: $white !important;
  box-shadow: 3px 3px 7px rgba($white, 0.25) !important;

  &::before {
    background: $white !important;
  }
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: none !important;
}

.ant-table-expanded-row {
  transition: all 600ms ease-in;
}

.table-clickable {
  .ant-table-cell {
    cursor: pointer;
  }
}

.d-none {
  display: none;
}

.signersList {
  .ant-comment-inner {
    padding-top: 0 !important;
  }
  .ant-comment-content {
    // display: flex;
    align-items: center;
  }
}

.chartAvgSpend,
.chartAvgUsage {
  .ant-card-body {
    height: calc(100% - 85px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-card-body::before {
    display: none !important;
  }
  .ant-card-body::after {
    display: none !important;
  }
  .ant-row-middle {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.ant-switch {
  margin-left: 6px !important;
}

.ant-radio-wrapper {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.contractTotalCost {
  .ant-tooltip-inner {
    padding: 0px;
    background-color: transparent !important;
    border-radius: 10px;
  }
}
.modalList {
  .ant-list-items {
    padding-left: 10px;
  }
  .ant-list-item {
    display: list-item;
    list-style: disc;
  }
}

.ant-card .h-inherit {
  height: inherit !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.doc-icon {
  color: $gray;
}

.w-100{
  width : 100%;
}

.text-center{
  text-align: center !important;
}