@import "./variables.scss";

.screen3 {
.directionSection{
    margin: " -300px 50px";
    text-Align:"right" ;
}

.vertical_text {
  writing-mode: vertical-lr;
  font-weight: 600;
  font-size: 114px;
  text-align: center; 
  line-height: 138px; 
  height: 909px; 
  width: 190px;
  transform: rotate(180deg);
 }

 
.busAndGateContainer {
    width: 1620px;
    height: 1495px;
    .busOutline {
      width: 418px;
      height: 418px;
    }
    .gateContainer {
      width: 560.79px;
      height: 494.35px;
      margin-top: 90px;
      background: #ED0677;
      border-top-right-radius:  190px;
    }
    .busContent {
      width: 1078.48px;
      height: 1090.58px;
      border: 1.64292px solid #000000;
      border-radius: 52.2558px;
      margin-top: -169px;
      display: flex;
      align-items: center;
    }
  }
.youAreHereContainer {
  margin-top: -607px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;


}
.dateTime {
  display: flex;
  justify-content: center;
  margin-top: 900px;
}
}
