@import "./variables.scss";

.screen8 {
	width: 1080px;
	height: 1920px;

	.textVariantH {
		font-family: "Montserrat";
		font-weight: 700;
		height: 464px;
		font-size: 114px;
		line-height: 138.78px;
		color: #ffffff;
	}

	.textTitles3h {
    font-family: "Montserrat";
    font-size: 27px;
    font-style: italic;
    font-weight: 500;
    line-height: 37px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #ffffff;
	}
  
	.textVariant2h {
    font-family: "Montserrat";
		font-size: 27px;
		font-weight: 700;
		line-height: 37px;
		letter-spacing: 0.015em;
		text-align: center;
    color: #ffffff;
	}


}
