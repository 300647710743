@import "./variables.scss";

.screen5 {
    width: 3533px;
    height: 6277.96px;

    .headerSection{
        margin-bottom: 323px;
    }
    .busAndGateContainer {
        margin-top: -640px;
        width: 1620px;
        height: 1495px;
        .busOutline {
          width: 225px;
          height: 470px;
        }
        .gateContainer {
          width: 868.79px;
          height: 668.35px;
          margin-left: 640px;
          padding: 99px;
          background:  #ED0677;
          border-top-right-radius:  190px;;
        }
        .busContent {
          width: 1630.48px;
          height: 1016.58px;
          border: 1.64292px solid #ffffff;
          border-radius: 52.2558px;
          padding: 70px;
          margin-top: -180px;
          display: flex;
          align-items: center;
        }
      }

      .pathSection{
        display: flex;
        justify-content: center;
        align-items: "center";
      } 
      
      .pathSectionSpacing{
        display: flex;
        margin-Top:70px;
        margin-bottom: 170px ;
        justify-content: center;
        align-items: "center";
      }
       .vertical_text {
        writing-mode: vertical-rl;
        font-weight: 700;
        font-size: 107.98px;
        text-align: center;
        line-height: 131.62px;
        font-style: normal;
        transform: rotate(180deg);
       }

      .vertical {
        border-right: 6px solid rgb(199, 199, 215);
        height: 3600px;
        margin-Top: -1590px;
        padding-right: 300px;
        display: flex;
        justify-content: flex-end;
        align-items: end;
      }

.dateTime {
    display: flex;
    align-items: flex-end;
    margin-Top: 900px;
    justify-content: center;
}
}
