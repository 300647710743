.flexBetween {
  display: flex;
  justify-content: space-between;
}

.page-title {
  margin-bottom: 40px;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.directionColumn {
  flex-direction: column;
}

.header {
  font-family: Montserrat;
  color: #ffffff;
  font-size: 52px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0.015em;
  text-align: center;
}
.texttitleSide1 {
  font-family: "Montserrat";
  font-size: 21px;
  font-style: italic;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}
.texttitleSide3 {
  font-family: "Montserrat";
  font-size: 21px;
  font-style: italic;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.texttitleSide2 {
  // font-family: "Montserrat";
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
}
.texttitleSide-2 {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 141.195px;
  line-height: 172px;
  text-align: center;
  color: #ffffff;
}
.texttitleBaggage {
  font-family: "Montserrat";
  font-size: 110px;
  font-weight: 600;
  line-height: 195px;
  letter-spacing: 0em;
  text-align: center;
}

.textTitle1 {
  font-size: 43px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Montserrat";
  color: #ffffff;
}

.textTitles2 {
  display: block;
  font-family: "Montserrat";
  color: #ffffff;
  font-size: 37px;
  font-style: italic;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
}

.textContent1 {
  font-family: "Montserrat";
  font-size: 150px;
  font-weight: 700;
  line-height: 209px;
  letter-spacing: 0.015em;
  text-align: center;
}

.textContentTitle2 {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 143.704px;
  line-height: 175px;
  text-align: end;
}

.textContentTitle {
  font-family: "Montserrat";
  font-size: 147px;
  font-weight: 600;
  line-height: 179px;
  letter-spacing: 0em;
  text-align: center;
}

.gateContainer {
  width: "1124px";
  height: "1090px";
}

.textVariantTime {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 120.704px;
  line-height: 81px;
  text-align: center;
  color: #ffffff;
}

.textVariantPotraitLarge {
  font-family: "Montserrat";
  font-weight: 700;
  color: #ffffff;
  font-size: 72px;
  line-height: 100px;
  letter-spacing: 0.015em;
  text-align: center;
}

.textVariantPotraitLarge2 {
  font-family: "Montserrat";
  font-size: 72px;
  font-style: italic;
  font-weight: 500;
  line-height: 100px;
  letter-spacing: 0.015em;
  text-align: center;
  color: #ffffff;
}

.textVariantPotraitLarge-2 {
  font-family: Montserrat;
  font-size: 78px;
  font-weight: 700;
  line-height: 108px;
  letter-spacing: 0.015em;
  text-align: center;
}

.textVariantPotraitMedium {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 65.83px;
  line-height: 80.25px;
  text-align: center;
}

.textVariantPotraitMediumtitle {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 65.83px;
  line-height: 80.25px;
  text-align: center;
}

.textVariantPotraitBaggage {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 65.65px;
  text-align: center;
  color: #ffffff;
}

.textVariantPotraitBaggage2 {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 134.65px;
  text-align: center;
  color: #ffffff;
}

.textVariantPotraitExtraSmall {
  font-family: "Montserrat";
  font-size: 64px;
  font-weight: 600;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: left;
}

.textVariantPotraitExtraSmall2 {
  font-family: "Montserrat";
  font-size: 64px;
  font-weight: 600;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: center;
}

.textVariantPotraitSmall {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 48.15px;
  line-height: 51.25px;
  text-align: center;
  color: #ffffff;
}
.textVariantPotraitText {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 44.959px;
  line-height: 55px;
}
.textVariantPotraitBoxContainer {
  font-family: "Montserrat";
  font-size: 80px;
  font-weight: 600;
  line-height: 98px;
  letter-spacing: 0em;
  text-align: center;
}

.textVariantExtraLarge {
  font-family: "Montserrat";
  font-size: 236px;
  font-weight: 700;
  line-height: 287px;
  letter-spacing: 0em;
  text-align: left;
}

.textLift {
  font-family: "Montserrat";
  font-size: 200px;
  font-weight: 600;
  line-height: 244px;
  letter-spacing: 0em;
  text-align: left;
}

.priorityContentText {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 201.07px;
  line-height: 246.15px;
}

.textVariant1 {
  font-family: "Montserrat";
  font-size: 196px;
  font-weight: 700;
  line-height: 239px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.textVariantSmall {
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 130.64px;
  line-height: 159px;
}
.textVariantDate {
  font-family: "Montserrat";
  color: #ffffff;
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
}

.textVariantVerySmall {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 96px;
  font-weight: 600;
  line-height: 117px;
  letter-spacing: 0em;
  text-align: left;
}

.textVariantMedium {
  // font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 94px;
  line-height: 114.59px;
}

.textVariantJourney {
  // font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 81px;
  line-height: 85.59px;
}

.textVariantBaggage {
  // font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 114px;
  line-height: 139.59px;
  text-align: center;
}
.textVariantBaggage2 {
  font-family: "Montserrat";
  font-size: 114px;
  font-weight: 600;
  line-height: 139px;
  letter-spacing: 0em;
  text-align: center;
}

.textVariantSectionLarge {
  // font-family: 'Montserrat';
  font-style: normal;
  font-size: 120.57px;
  font-weight: 600;
  line-height: 146%;
  text-align: center;
}

.textVariantSectionSmall {
  // font-family: 'Montserrat';
  font-style: normal;
  font-size: 59.97px;
  font-weight: 400;
  line-height: 95%;
  text-align: center;
  color: #ffffff;
}

.verticalContent {
  .textVariantSmall {
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 70px;
  }
}

.headertwo {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  color: #ffffff;
  width: "1188px";
  font-size: 46px;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: 0.015em;
  text-align: center;
}

.infoText {
  font-family: "Montserrat";
  font-size: 48px;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.infoText2 {
  font-family: "Montserrat";
  font-size: 48px;
  font-style: italic;
  font-weight: 500;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.Arivals {
  font-family: "Montserrat";
  font-size: 64px;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.Arivals2 {
  font-family: "Montserrat";
  font-size: 68px;
  font-style: italic;
  font-weight: 200;
  line-height: 83px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
