@import "./variables.scss";

.busAndGateContainer {
  .busOutline {
    position: relative;
    top: 70px;
    width: 130px;
    height: 130px;
  }
  .gateContainer {
    position: relative;
    top: 60px;
    right: -100px;
    z-index: 999;
    width: 209.35px;
    height: 196.51px;
    background: #ed0677;
    color: white;
    border-top-right-radius: 60px;
  }
  .busContent {
    width: 380.48px;
    height: 340.58px;
    border: 1.64292px solid #ffffff;
    border-radius: 32.2558px;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }
}

.businessClassContainer {
  display: flex;
  align-items: center;
  .busContent {
    // width: 474.48px;
	width : 400px;
    padding: 4rem 2rem;
    border: 1.64292px solid #ffffff;
    border-radius: 32.2558px;
    display: flex;
    margin-bottom: 20px;
	margin-right: 40px;
    // align-items: center;
  }
  .busOutline {
    position: relative;
    top: -10px;
    width: 130px;
    height: 130px;
  }
  .gateContainer {
    position: relative;
    // top: 60px;
    right: -100px;
    z-index: 999;
    width: 209.35px;
    height: 196.51px;
    background: #ed0677;
    color: white;
    border-top-right-radius: 60px;
	top: -32px;
  }
}

.dutyFreeContainer {
  .img {
    width: 1150px;
    height: 2783px;
    margin-left: 200px;
  }
  .contentContainer {
    width: 2103.3px;
    height: 988.56px;
    display: flex;
    .verticalContent {
      height: 100%;
      width: 175px;
    }
    .titleContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.textVariant1 {
  // text-align: center;
  font-weight: 600;
  width: 500px;
  // height: 420px;
  font-size: 136px;
  line-height: 60px;
}

.textTitles3 {
  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 500;
  line-height: 55px;
  width: 500px;
  // height: 342px;
  font-style: italic;
  // text-align: center;
  color: #ffffff;
}

.youAreHereContainer {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.screen1 {
  .textTitle1 {
    // font-size: 35px;
    font-weight: 600;
    // line-height: 52px;
  }
  .businessClassContainer {
    .textTitle1 {
      font-size: 33px;
      font-weight: 600;
      line-height: 52px;
    }
    .textTitles2 {
      font-size: 32px;
    //   font-style: italic;
    //   font-weight: 500;
    //   line-height: 34px;
    }
	.textTitle3{
		font-size: 43px !important;
	}
  }
}
