@import "./variables.scss";

.textTitles3b{
  font-family: "Montserrat";
  font-size: 114px;
  font-weight: 500;
  line-height: 170px;
  width: 1325px;
  height: 342px;
  font-style: italic;
  text-align: center;
  color: #ffffff;
}

.textTitles4b{
  width: 670px !important;
}
.vectorGroup1{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1900px;
  height: 550px;
  border: 2px solid #189AA7;
  border-radius: 56px;
}


.textVariant2{
  font-family: "Montserrat";
  font-weight: 700;
  // width: 740px;
  height: 464px;
  font-size: 114px;
  line-height: 138.78px;
  color: #ffffff;
}

.screen2 {
  .arrowContainer {
    width: 978px;
    height: 1924.88px;
  }

  .busAndGateContainer {
    width: 1820px;
    height: 1590px;
    padding-left: 190px;
    .busOutline {
      width: 418px;
      height: 698px;
    }
    .gateContainer {
      width: 618.79px;
      height: 634.35px;
      background: #ed0677;
      border-top-right-radius: 240px;
    }
    .busContent {
      width: 1921.48px;
      height: 897.58px;
      border: 1.64292px solid #000000;
      border-radius: 52.2558px;
      margin-top: -150px;
      display: flex;
      align-items: center;
    }
  }

  .priorityLineContainer {
    width: 3006px;
    height: 1366px;
    top: 4344px;
    left: 264px;
    border-radius: 67.310px;
    margin-top: 262px;
    border: 20.11624px dashed rgba(9, 9, 9, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 130px;
    text-align: center;

    .iconList {
      margin: auto;
      display: flex;
      align-content: center;
      justify-content: space-between;
      width: 2202.49px;
      height: 588.97px;
      padding: 96px 125px;
    }
    .leftArrow {
      width: 740px;
      height: 300px;
    }
  }

  .escalatorContainer {
    display: flex;
    align-items: flex-end;
  }
}
