@import "./variables.scss";

.screen4 {
   
    .headerSection{
        margin-bottom: 423px;
    }
   .direction{
    padding-left: 90px;

   }

   .mainTerminal{
    .mainSectionTerminal{
        display: "flex";
         text-align: "center";
         justify-content: "Center" 
    }
   }

    .busAndGateContainer {
        width: 1620px;
        height: 1495px;
        .busOutline {
          width: 418px;
          height: 418px;
        }
        .gateContainer {
          width: 560.79px;
          height: 494.35px;
          margin-top: 90px;
          background:  #ED0677;
          border-top-right-radius:  140px;
        }
        .busContent { 
          width: 1078.48px;
          height: 1090.58px;
          border: 1.64292px solid #000000;
          border-radius: 52.2558px;
          margin-top: -169px;
          display: flex;
          align-items: center;
        }
      }
      .vertical {
        border-right: 6px solid rgb(0, 0, 0);
        height: 3900px;
        margin-Top: -2070px;
        padding-right: 300px;
        display: flex;
        justify-content: flex-end;
        align-items: end;
      }

      .vertical_text {
        writing-mode: vertical-lr;
        font-weight: 700;
        font-size: 114.83px;
        text-align: center; 
        line-height: 138.97px; 
        height: 1009px; 
        width: 170px;
        transform: rotate(180deg);
       }

      
.dateTime {
  display: flex;
  align-items: flex-end;
  margin-Top: 480px;
  padding-Top: 300px ;
  margin-bottom: -1309px ;
  justify-content: center;
}
}
