@import "./variables.scss";

.textTitles3I{
  font-family: "Montserrat";
  font-size: 55px;
  font-weight: 500;
  line-height: 100px;
  width: 1325px;
  font-style: italic;
  text-align: center;
  color: #ffffff;
}

.textVariant2I{
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 54px;
  line-height: 100.78px;
  color: #ffffff;
}

.textVariant2IB{
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 76px;
  line-height: 100.78px;
  color: #ffffff;
}

.busGif div div svg{
  transform: none !important;
}

.vectorGroupI{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1900px;
}



.headerI {
  font-family: Montserrat;
  font-size: 73px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0.015em;
  text-align: center;
  color: #ffffff;
}

.headertwoI {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 72px;
  color: #ffffff;
  width: "1188px";
}

.textTitleI {
  font-family: "Montserrat";
  font-size: 72px;
  font-weight: 600;
  line-height: 147px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}


.screen9 {
  width: 3840px;
  height: 1723px;

  .page-title {
    margin-bottom: 40px;
  }

  .direction {
    display: flex;
    align-items: end;
    justify-content: center;
    padding-left: -20px;
    padding-bottom: 111px;
  }
  .vertical-text {
    writing-mode: vertical-lr;
    margin-bottom: -89px;
    text-align: center;
    padding: 0.5em;
    height: 400px;
    width: 100px;
    font-weight: 700;
    font-size: 44.4879px;
    line-height: 54px;
    transform: rotate(180deg)        
  }
  .busAndGateContainer {
    width: 1420px;
    height: 895px;
    .busOutline {
      margin: "780px 0px 0px 0px ";
      
    }

    .busContent {
      width: 1142.48px;
      height: 418.58px;
      border: 1.64292px solid #000000;
      border-radius: 22.2558px;
      margin-top: 99px;
      display: flex;
      justify-content: center;
    }
  }
}
