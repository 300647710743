@import "./variables.scss";

.screen6 {
    width: 3533px;
    height: 6223.96px;
    padding-left: 90px;
    
    .headerSection{
      margin-bottom: 323px;
  }

  .infoSection{
    text-align: "center";
}

.busAndGateContainer {
  width: 1620px;
  height: 1495px;
  .busOutline {
    width: 418px;
    height: 418px;
  }
  .gateContainer {
    width: 590.79px;
    height: 504.35px;
    margin-top: 90px;
    background:  #ED0677;
    border-top-right-radius:  190px;
  }
  .busContent {
    width:  1308.57px;
    height: 1290.58px;
    border: 1.64292px solid #000000;
    border-radius: 52.2558px;
    margin-top: -169px;
    display: flex;
    align-items: center;
  }
}

.directionSection{
    margin: " -300px 80px";
    text-align: "left" 
}

.escalatorContainer {
  display: flex;
  align-items: flex-end;
}
.dateTime {
  display: flex;
  justify-content: center;
  margin-top: 700px;
}
.vertical_text {
  writing-mode: vertical-lr;
  font-weight: 700;
  font-size: 114px;
  text-align: center; 
  line-height: 138px; 
  height: 909px; 
  width: 190px;
  transform: rotate(180deg);
 }
 .youAreHereContainer{
  padding-right: 20px;
 }
}

