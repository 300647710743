@import "./variables.scss";

.screen7 {
  width: 3598px;
  height: 2023.96px;
  .direction {
    display: flex;
    align-items: end;
    justify-content: center;
    padding-bottom: 200px;
  }
  .busAndGateContainer {
    width: 680px;
    height: 895px;
    padding-left: 99px !important;
    .busOutline {
      width: 198px;
      height: 198px;
    }
    .gateContainer {
      width: 270.79px;
      height: 270.35px;
      background: #ed0677;
      border-top-right-radius: 70px;
    }
    .busContent {
      width: 453.48px;
      height: 406.58px;
      border: 1.64292px solid #000000;
      border-radius: 52.2558px;
      margin-top: -100px;
      display: flex;
      align-items: center;
    }
  }
}

.screen7-header {
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.screen7-footer {
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.screen7 {
  .textVariantPotraitLarge {
	margin-bottom: 20px;
    font-size: 84px;
  }

  .textVariantPotraitLarge2 {
    font-size: 84px;
  }
  .textVariantSectionSmall {
	font-size: 72px;
	font-weight: 400;
  }
}
